const SocialMediaUtils = {};

const socialMediaDetails = {
    'facebook': {
        id: 'facebook',
        name: 'Facebook',
        shortName: 'FB',
        homepage: 'https://www.facebook.com',
        faIconClass: 'fab fa-facebook-square',
        colorPrimary: '#3b5998',
        colorSecondary: null,
        profileUrlPattern: 'https://www.facebook.com/{slug}' // @future IMPORTANT : Facebook has URL params for users with no slug.
    },
    'twitter': {
        id: 'twitter',
        name: 'Twitter',
        shortName: null,
        homepage: 'https://twitter.com',
        faIconClass: 'fab fa-twitter',
        colorPrimary: '#1da1f2',
        colorSecondary: null,
        profileUrlPattern: 'https://twitter.com/{slug}'
    },
    'instagram': {
        id: 'instagram',
        name: 'Instagram',
        shortName: null,
        homepage: 'https://www.instagram.com',
        faIconClass: 'fab fa-instagram',
        colorPrimary: '#4c5fd7',
        colorSecondary: '#c32aa3',
        profileUrlPattern: 'https://www.instagram.com/{slug}/'
    }
};

const socialMediaDetailsList = Object.values(socialMediaDetails);

/**
 * Given a field name this operations tries to detect the corresponding social media
 * and returns a ready-to-use HTML link.
 *
 * @param fieldName the fieldName that contains traits about the social media
 * @param personal the URL, the username or any identifying characteristic
 */
SocialMediaUtils.detectSocialMediaAndBuildExternalLink = function (fieldName, personal) {
    if (typeof fieldName !== 'string') return null;
    if (typeof personal !== 'string') return null;
    if (fieldName.trim() === '') return null;
    if (personal.trim() === '') return null;

    let link = null;
    for (const sm of socialMediaDetailsList) {
        if (fieldName.startsWith(sm.id)) {
            const url = personal.startsWith('http') ? personal : sm.profileUrlPattern.replace('{slug}', personal);
            link = '<a href="' + url + '" target="_blank" rel="noreferrer" referrerpolicy="no-referrer" style="color: ' + sm.colorPrimary + '"><i class="' + sm.faIconClass + '"></i></a>';
            break;
        }
    }
    return link;
};

export default SocialMediaUtils;
