<template>
    <div class="GctCommonGroup">
        <!-- HEADER -->
        <div class="GctCommonGroup__Header d-flex align-items-center justify-content-start mb-2" v-if="displayHeader">
            <p class="mb-0 mr-1">{{ $t('Matches') }}</p>
            <select class="form-control form-control-sm g-width-auto mr-1"
                    v-model="bgc.operator">
                <option v-for="operatorItem in bgc.specific.operators"
                        :key="operatorItem.value"
                        :label="$t(operatorItem.labelAlt)"
                        :value="operatorItem.value">{{ $t(operatorItem.labelAlt) }}</option>
            </select>
            <!-- assuming that operator can be OR or AND fix sentence in Greek -->
            <p class="mb-0">{{ bgc.operator === 'AND' ? $t('of_the_following_and') : $t('of_the_following_or') }}:</p>
        </div>
        <div class="GctCommonGroup__Body mb-2">
            <div class="py-2" v-for="(child, index) in bgc.children" :key="child.specific.rId">
                <gct-common-filter v-if="child.type === 'COMMON_FILTER'"
                                   :key="'COMMON_FILTER' + child.specific.rId"
                                   :bgc-initial="child"
                                   :index="index"
                                   v-on:change="onChildChange"
                                   v-on:remove="onChildRemove"></gct-common-filter>
                <gct-common-range-filter v-else-if="child.type === 'COMMON_RANGE_FILTER'"
                                         :key="'COMMON_RANGE_FILTER' + child.specific.rId"
                                         :bgc-initial="child"
                                         :index="index"
                                         v-on:change="onChildChange"
                                         v-on:remove="onChildRemove"></gct-common-range-filter>
                <gct-common-array-filter v-else-if="child.type === 'COMMON_ARRAY_FILTER'"
                                         :key="'COMMON_ARRAY_FILTER' + child.specific.rId"
                                         :bgc-initial="child"
                                         :index="index"
                                         v-on:change="onChildChange"
                                         v-on:remove="onChildRemove"></gct-common-array-filter>
                <gct-common-communication-subscription-filter v-else-if="child.type === 'COMMUNICATION_SUBSCRIPTION_FILTER'"
                                                              :key="'COMMUNICATION_SUBSCRIPTION_FILTER' + child.specific.rId"
                                                              :bgc-initial="child"
                                                              :index="index"
                                                              v-on:change="onChildChange"
                                                              v-on:remove="onChildRemove"></gct-common-communication-subscription-filter>
                <div v-else key="INVALID">
                    <p class="mb-0 small weight-6 text-danger">{{ $t('Invalid') }} / {{ child.specific.field }}</p>
                </div>
            </div>
        </div>
        <div class="GctCommonGroup__Footer">
            <bgc-select v-on:select="addChild"></bgc-select>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import GcConfig from './gc-config';
import GctCommonFilter from './gct-common-filter';
import GctCommonRangeFilter from './gct-common-range-filter';
import GctCommonArrayFilter from './gct-common-array-filter';
import GctCommonCommunicationSubscriptionFilter from './gct-communication-subscription-filter';
import BgcSelect from './bgc-select';

/**
 * Responsible for GenericCriteriaType COMMON_GROUP.
 *
 * Normally back-end supports nested instances without any limitation.
 * But front-end does not support this functionality currently (and I think forever).
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 */
export default {
    name: 'GctCommonGroup',
    components: {
        GctCommonFilter,
        GctCommonRangeFilter,
        GctCommonArrayFilter,
        GctCommonCommunicationSubscriptionFilter,
        BgcSelect
    },
    props: {
        bgcInitial: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            bgc: null
        };
    },
    computed: {
        displayHeader () {
            return this.bgc.children.length > 1;
        }
    },
    beforeMount () {
        this.bgc = cloneDeep(this.bgcInitial);
        // On each change, send data to parent.
        this.$watch('bgc', {
            handler: debounce(function () {
                this.signalChange();
            }, GcConfig.commonDebounce),
            deep: true
        });
    },
    methods: {
        signalChange () {
            this.$emit('change', {
                bgc: cloneDeep(this.bgc),
                index: this.index
            });
        },
        signalRemove () {
            this.$emit('remove', this.index);
        },

        // Children
        // these methods trigger watcher which notifies parent components.
        addChild ($event) {
            if ($event === null) return;
            // @future We should validate the provided object.
            const newBgc = cloneDeep($event);
            newBgc.specific['rId'] = 'bgc-' + new Date().getTime(); // reactivity and rendering
            this.bgc.children.push(newBgc);
        },
        onChildChange ({ bgc, index }) {
            if (typeof index !== 'number') return;
            // @future We should validate if the provided index is in range.
            // this.bgc.children[index] = bgc; // data from child are cloned.
            // @see https://vuejs.org/v2/guide/reactivity.html#For-Arrays
            this.$set(this.bgc.children, index, bgc);
        },
        onChildRemove ($event) {
            if (typeof $event !== 'number') return;
            // @future We should validate if the provided index is in range.
            this.bgc.children.splice($event, 1);
            // If it's empty it should be removed.
            if (this.bgc.children.length <= 0) this.signalRemove();
        }
    }
};
</script>

<style lang="scss">
    .GctCommonGroup {
        position: relative;
        margin: 0 0 .625rem;
        padding: .625rem 1.25rem;
        background-color: #f8f9fa;
        border: 1px solid #dcdcdc;
        border-radius: .1333rem;
    }
    .GctCommonGroup:before {
        content: "";
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 1.25rem;
        width: 2px;
        height: 0.83333rem;
        background-color: #eceef0;
    }
</style>
