<template>
    <div class="PeopleBodyListingPagination mb-3 mb-md-4 C-D-None-md-Up">
        <b-pagination class="mb-0 BootstrapPagination--Default"
                      size="md"
                      pills
                      :value="paginationPagePlusOne"
                      :per-page="paginationSize"
                      :total-rows="peopleCount"
                      :limit="1"
                      :hide-goto-end-buttons="false"
                      :disabled="false"
                      align="right"
                      @change="onBootstrapPaginationChange"></b-pagination>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Pagination for small devices.
 *
 * @author Dimitris Gkoulis
 * @createdAt 5 August 2020
 */
export default {
    name: 'PeopleBodyListingPagination',
    computed: {
        ...mapGetters('people', {
            // 'people' sub-module
            peopleCount: 'peopleCount',
            // 'ws-sd-pagination' sub-module
            paginationPagePlusOne: 'paginationPagePlusOne',
            paginationSize: 'paginationSize'
        })
    },
    methods: {
        onBootstrapPaginationChange ($event) {
            if ($event === this.paginationPagePlusOne) return; // If by mistake of because of bootstrap is the same page, do nothing.
            this.$store.dispatch('people/paginationChangeFromBootstrap', { page: $event }).then(() => void 0).catch(() => void 0);
        }
    }
};
</script>
