<template>
    <div class="PeopleHeaderSettings bg-white border-bottom border-left border-right p-0" v-if="uiSettingsOpened">
        <div class="container">
            <div class="d-flex align-items-center justify-content-end">
                <div class="flex-fill border-left p-2">
                    <p class="mb-1 DiscreetEmphasis">{{ $t('People per page') }}</p>
                    <b-form-group>
                        <b-form-radio-group id="radio-group-1" name="radio-group-1"
                                            :checked="paginationSize"
                                            @change="onWorkspacePaginationSizeChange">
                            <b-form-radio :value="30">30</b-form-radio>
                            <b-form-radio :value="50">50</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                <div class="flex-fill border-left border-right p-2">
                    <p class="mb-1 DiscreetEmphasis">{{ $t('ws_view_label') }}</p>
                    <b-form-group>
                        <b-form-radio-group id="radio-group-3" name="radio-group-3"
                                            :checked="workspaceWorkingView"
                                            @change="onWorkspaceViewChange">
                            <b-form-radio :value="'DEFAULT'">{{ $t('ws_view_DEFAULT') }}</b-form-radio>
                            <b-form-radio :value="'COMPACT'">{{ $t('ws_view_COMPACT') }}</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Settings (page size, view type, search type, etc) section for people view.
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 July 2020
 */
export default {
    name: 'PeopleHeaderSettings',
    computed: {
        ...mapGetters('people', {
            // 'workspace' sub-module
            workspaceWorkingView: 'workspaceWorkingView',
            // 'ws-sd-pagination' sub-module
            paginationSize: 'paginationSize',
            // 'ui' sub-module
            uiSettingsOpened: 'uiSettingsOpened'
        })
    },
    methods: {
        onWorkspacePaginationSizeChange ($event) {
            this.$store.dispatch('people/paginationChangeSize', $event).then(() => void 0).catch(() => void 0);
        },
        onWorkspaceViewChange ($event) {
            this.$store.commit('people/setWorkspaceWorkingView', $event);
        }
    }
};
</script>

<style lang="scss">
    .PeopleHeaderSettings {

        .form-group {
            margin-bottom: 0;
        }
    }
</style>
