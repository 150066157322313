<template>
    <div class="PeopleHeaderSorting py-2">
        <div class="container">
            <div class="d-flex align-items-center justify-content-start" style="min-height: 32px;">
                <!-- PAGINATION DETAILS -->
                <p class="mb-0 text-muted small" style="white-space: nowrap;">
                    <span v-html="peopleFetching ? $t('common_state_loading') + '...' : getPaginationTranslatedSentence()"></span>
                    <!--
                    {{ $tc('common_pagination_results_phrase', peopleCount, { count: peopleCount }) }}
                    -->
                    <span class="C-D-None-md-Down" v-if="sortingSortersCount > 0"> {{ $t('sorted by') }}</span>
                </p>
                <!-- ACTIVE SORTERS -->
                <div class="d-flex align-items-center justify-content-between mini-horizontal-scroll ml-1 mr-1 C-D-None-md-Down">
                    <div class="sorter-listing-item ml-1 mr-1"
                         v-for="(sorter, index) in sortingSorters"
                         :key="sorter.field">
                        <p class="mb-0 text-muted small" style="white-space: nowrap;">
                            {{ getSorterFieldTranslatedLabel(sorter.field) }}
                            <span class="sorter-listing-item__name" style="font-size: 80%; font-weight: bold">({{ $t(sorter.direction) }})&nbsp;</span>
                            <i style="font-size: 80%"
                               class="fas fa-times cursor-pointer sorter-listing-item__close_icon"
                               :title="$t('common_action_remove')"
                               @click="removeSorter(index)"></i>
                        </p>
                    </div>
                </div>
                <!-- NEW SORTER ACTION -->
                <p class="mb-0 text-muted small cursor-pointer C-D-None-md-Down"
                   style="text-decoration: underline; text-decoration-style: dotted; white-space: nowrap;"
                   @click="makeAddSorterVisible"
                   v-if="displayAddNewSorterButton">{{ $t('Add sorter') }}</p>
                <!-- NEW SORTER INLINE FORM -->
                <div class="d-flex align-items-center justify-content-start ml-2 mr-2" v-if="addSorterVisible">
                    <select class="form-control form-control-sm mr-1" v-model="newSorterField">
                        <option v-for="fieldOption in sortingEnabledFieldOptions"
                                :key="fieldOption.field"
                                :value="fieldOption.field">{{ fieldOption.label }}</option>
                    </select>
                    <select class="form-control form-control-sm mr-1" v-model="newSorterDirection">
                        <option v-for="directionOption in sortingEnabledDirectionOptions"
                                :key="directionOption"
                                :value="directionOption">{{ $t(directionOption) }}</option>
                    </select>
                    <button class="btn btn-sm btn-secondary-alt" @click="addSorter">{{ $t('common_action_add') }}</button>
                </div>
                <!-- PAGINATION -->
                <b-pagination class="mb-0 ml-auto"
                              size="sm"
                              pills
                              :value="paginationPagePlusOne"
                              :per-page="paginationSize"
                              :total-rows="peopleCount"
                              :limit="1"
                              :hide-goto-end-buttons="false"
                              :disabled="false"
                              @change="onBootstrapPaginationChange"></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GSortersProvider from '@/modules/People/gc-builder/g-sorters-provider';

function calcPaginationInformation (page, pageSize, pageEntriesCount, totalEntriesCount, pageStartsFromOne = true) {
    // @future Validate parameters.
    const pageFirstItem = 1 + (page - (pageStartsFromOne ? 1 : 0)) * pageSize;
    const pageLastItem = pageFirstItem + pageEntriesCount - 1;
    return {
        page: page,
        actualPage: pageStartsFromOne ? page - 1 : page,
        pageSize: pageSize,
        pageEntriesCount: pageEntriesCount,
        totalEntriesCount: totalEntriesCount,
        pageStartsFromOne: pageStartsFromOne,
        pageStartsFromZero: !pageStartsFromOne,
        pageFirstItem: pageFirstItem,
        pageLastItem: pageLastItem
    };
}

/**
 * Pagination and sorting functionality and information.
 *
 * NOTICE: We don't disable actions while people are loading.
 * It's expected that user has no time to perform sequential too many actions.
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 July 2020
 */
export default {
    name: 'PeopleHeaderPageable',
    data () {
        return {
            addSorterVisible: false,
            newSorterField: null,
            newSorterDirection: 'ASC'
        };
    },
    computed: {
        ...mapGetters('people', {
            // 'people' sub-module
            peopleFetching: 'peopleFetching',
            peopleLength: 'peopleLength',
            peopleCount: 'peopleCount',
            // 'ws-sd-pagination' sub-module
            paginationPagePlusOne: 'paginationPagePlusOne',
            paginationSize: 'paginationSize',
            // 'ws-sd-sorting' sub-module
            sortingSorters: 'sortingSorters',
            sortingEnabledFieldOptions: 'sortingEnabledFieldOptions',
            sortingEnabledDirectionOptions: 'sortingEnabledDirectionOptions'
        }),
        sortingSortersCount () {
            return this.sortingSorters.length;
        },
        displayAddNewSorterButton () {
            if (this.sortingSortersCount > 2) {
                return false;
            }
            return !this.addSorterVisible;
        },
        paginationInformation () {
            return calcPaginationInformation(
                this.paginationPagePlusOne,
                this.paginationSize,
                this.peopleLength,
                this.peopleCount,
                true);
        }
    },
    methods: {
        // helper function for HTML.
        getSorterFieldTranslatedLabel (field) {
            return GSortersProvider.getTranslatedLabelByFieldPathOrFallback(field);
        },
        makeAddSorterVisible () {
            this.addSorterVisible = true;
        },
        addSorter () {
            // Get the values.
            const fieldTmp = this.newSorterField;
            const directionTmp = this.newSorterDirection;

            // Check data to avoid redundant requests.
            if (fieldTmp === null || directionTmp === null) {
                this.addSorterVisible = false; // Hide form.
                return;
            }
            if (typeof fieldTmp !== 'string' || typeof directionTmp !== 'string') {
                this.addSorterVisible = false; // Hide form.
                return;
            }
            if (directionTmp !== 'ASC' && directionTmp !== 'DESC') {
                this.addSorterVisible = false; // Hide form.
                return;
            }

            // Reset the values.
            this.newSorterField = null;
            this.newSorterDirection = 'ASC';

            // Hide form.
            this.addSorterVisible = false;

            // Add sorter.
            this.$store.dispatch('people/sortingAddSorter', {
                field: fieldTmp,
                direction: directionTmp
            }).then(() => void 0).catch(() => void 0);
        },
        removeSorter (index) {
            this.$store.dispatch('people/sortingRemoveSorterByIndex', index).then(() => void 0).catch(() => void 0);
        },
        onBootstrapPaginationChange ($event) {
            if ($event === this.paginationPagePlusOne) return; // If by mistake of because of bootstrap is the same page, do nothing.
            this.$store.dispatch('people/paginationChangeFromBootstrap', { page: $event }).then(() => void 0).catch(() => void 0);
        },

        getPaginationTranslatedSentence () {
            return this.peopleCount === 0 ? this.$t('no_results') : this.$t('pagination_to_of', {
                start: this.paginationInformation.pageFirstItem,
                end: this.paginationInformation.pageLastItem,
                total: this.paginationInformation.totalEntriesCount
            });
        }
    }
};
</script>

<style lang="scss">
    .PeopleHeaderSorting {

        .sorter-listing-item {
            border: 1px solid #9e9e9e;
            border-radius: 2px;
            padding: 0 0.4em;
        }
        .form-control {
            background: transparent !important;
        }
        .mini-horizontal-scroll {
            overflow-x: hidden;
            overflow-y: hidden;
        }
        .mini-horizontal-scroll:hover {
            overflow-x: auto;
        }
        .mini-horizontal-scroll::-webkit-scrollbar-track {}
        .mini-horizontal-scroll::-webkit-scrollbar {
            // width: 6px;
            height: 8px;
            background-color: transparent;
        }
        .mini-horizontal-scroll::-webkit-scrollbar-thumb {
            background-color: #6c757d !important;
            border-radius: 4px;
            border: 2px solid #f8f9fa;
        }
        .b-pagination {

            .page-item {

                &.active {
                    display: none !important;
                }
                &.disabled {
                    cursor: not-allowed;

                    .page-link {
                        background-color: #f8f9fa;
                    }
                }
            }
        }
    }
</style>

<i18n>
{
    "en": {
        "no_results": "no results found",
        "pagination_to_of": "<b>{start}</b> to <b>{end}</b> of <b>{total}</b>"
    },
    "el": {
        "no_results": "κανένα αποτέλεσμα",
        "pagination_to_of": "<b>{start}</b> έως <b>{end}</b> από <b>{total}</b>"
    }
}
</i18n>
