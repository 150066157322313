<template>
    <div v-bind:class="extraClass"
         class="priority-meter"
         data-behavior="set-priority"
         :data-priority="value"
         data-signup-id=""
         data-tooltip="true"
         data-placement="top"
         title=""
         data-original-title="Set Priority"
         v-bind:style="priorityMeterStyle"
         v-if="showComponent">

        <svg :width="styling.meterWidth" :height="styling.meterHeight" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
                <rect class="level" fill="currentColor" x="0" y="0" :width="styling.meterWidth" height="3.95767196"></rect>
                <rect class="level" fill="currentColor" x="0" y="5" :width="styling.meterWidth" height="3.95767196"></rect>
                <rect class="level" fill="currentColor" x="0" y="10" :width="styling.meterWidth" height="3.95767196"></rect>
                <rect class="level" fill="currentColor" x="0" y="15" :width="styling.meterWidth" height="3.95767196"></rect>
                <rect class="level" fill="currentColor" x="0" y="20" :width="styling.meterWidth" height="3.95767196"></rect>
            </g>
        </svg>

    </div>
</template>

<script>
export default {
    name: 'PersonTaxonomyPriorityMeterMini',
    props: {
        value: {
            type: Number,
            default: 0,
            required: true
        },
        extraClass: {
            type: String,
            default: '',
            required: false
        },
        hideIf0: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data () {
        return {
            styling: {
                meterWidth: 12,
                meterHeight: 24
            }
        };
    },
    computed: {
        priorityMeterStyle () {
            return {
                'width': this.meterWidth + 'px'
            };
        },
        showComponent () {
            if (this.hideIf0 && this.value === 0) {
                return false;
            }
            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
    .priority,
    .priority-meter {
        display: inline-block;
        position: relative;
        // width: 12px;
    }
    .priority-meter .level {
        fill: #e0e4e5;
    }
    .priority-meter[data-priority="1"] .level:nth-child(n+5),
    .priority-meter[data-priority="2"] .level:nth-child(n+4),
    .priority-meter[data-priority="3"] .level:nth-child(n+3),
    .priority-meter[data-priority="4"] .level:nth-child(n+2),
    .priority-meter[data-priority="5"] .level:nth-child(n+1) {
        fill: #899e2a;
    }
</style>
