/****************************************************************************************************
 * Hardcoded GenericSorter instances Provider.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';
import i18n from '@/common/plugins/i18n';

// labels (in 'sortingPoliticsOptionList' and 'sortingCommonOptionList'
// are used as translation key and as fallback value if the translation does not exist.
// IMPORTANT: unlike other services this service relies on translations being in the general repository.
// DomainTranslations are not being used in here (@future change that?).

const sortingPoliticsOptionList = []; // Add field in here.

const sortingCommonOptionList = [
    {
        field: 'id',
        label: 'ID',
        used: false
    },
    {
        field: 'createdDate',
        label: 'Created Date',
        used: false
    },
    {
        field: 'lastModifiedDate',
        label: 'Last Modified Date',
        used: false
    },
    {
        field: 'properties.firstName',
        label: 'First Name',
        used: false
    },
    {
        field: 'properties.lastName',
        label: 'Last Name',
        used: false
    },
    {
        field: 'properties.dateOfBirth',
        label: 'Date of Birth',
        used: false
    },
    {
        field: 'properties.priority',
        label: 'Priority',
        used: false
    }
];

const sortingOptionsByField = [
    ...sortingPoliticsOptionList,
    ...sortingCommonOptionList
].reduce(function (accumulator, current) {
    accumulator[current.field] = current.label;
    return accumulator;
}, {});

function getTranslatedFieldLabelOrFallback (key, fallback = 'Unknown', translateFallback = true) {
    if (i18n.te(key)) return i18n.t(key);
    return translateFallback ? i18n.t(fallback) : fallback;
}

const GSortersProvider = {};

GSortersProvider.getSortingOptionList = function () {
    let politicsList = [];
    if (window.cs3w.get('politicsAddOnEnabled', false)) {
        politicsList = sortingPoliticsOptionList;
    }
    return [
        ...sortingCommonOptionList,
        ...politicsList
    ].map(function (obj) {
        const cloned = cloneDeep(obj);
        cloned.label = getTranslatedFieldLabelOrFallback(obj.label, obj.label);
        return cloned;
    });
};

GSortersProvider.getTranslatedLabelByFieldPathOrFallback = function (field, fallback = 'Unknown', translateFallback = true) {
    // @future We should create an additional method that returns field as it is if translation does not exist.
    if (typeof field !== 'string') return translateFallback ? i18n.t(fallback) : fallback;
    if (!sortingOptionsByField.hasOwnProperty(field)) return translateFallback ? i18n.t(fallback) : fallback;
    return getTranslatedFieldLabelOrFallback(sortingOptionsByField[field], fallback, translateFallback);
};

export default GSortersProvider;
