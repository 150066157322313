<!--suppress JSUnresolvedVariable -->
<template>
    <div class="PersonListingItemDefault card p-4 mb-3 mb-md-4 position-relative">
        <div class="row">
            <div class="col-auto">
                <div class="avatar-container position-relative">
                    <!--suppress JSUnresolvedVariable, HtmlUnknownTarget -->
                    <avatar :username="person.properties.avatarLetters"
                            :size="60"
                            :src="person.properties.avatarUrl"
                            style="min-width: 60px;"
                            class="person-title-avatar"
                            backgroundColor="#dddddd"
                            color="#666666"></avatar>
                    <!--
                    <span class="avatar-bottom-detail"><span class="badge badge-pill badge-success">5sc</span></span>
                    -->
                </div>
            </div>
            <div class="col-12 col-md-10 mt-2 mt-md-0">
                <div class="information-section row align-center mb-2 mb-md-3">
                    <div class="col-auto mw-100">
                        <router-link tag="p"
                                     class="mb-0 d-inline mr-1 size-6 weight-7 line-height-2 cursor-pointer"
                                     :to="{ name: 'person', params: {id: person.id} }">
                            {{ person.properties.name }} <span v-if="person.properties.deceased" :title="$t('Deceased')"> †</span>
                        </router-link>
                    </div>
                    <div class="col-12 mt-0 mt-md-1" v-if="displayFullTitleAndSocialMedia">
                        <p class="mb-0 d-inline dot-separator" v-if="fullTitle">{{ fullTitle }}</p>
                        <p class="mb-0 d-inline dot-separator" v-if="displaySocialMediaList">
                            <span class="mr-1" v-for="(socialMediaLink, index) in socialMediaList" :key="index" v-html="socialMediaLink"></span>
                        </p>
                    </div>
                </div>
                <!--
                @future display this when person has incomplete information?
                <div class="mb-1">
                    <p class="mb-0 text-muted">Προσθέστε περισσότερες πληροφορίες για αυτό το Άτομο…</p>
                </div>
                -->
                <div class="information-section mb-1" v-if="demographics">
                    <b>{{ $t('Demographics') }}: </b>
                    <span>{{ demographics }}</span>
                </div>
                <div class="information-section mb-1" v-if="contact">
                    <b>{{ $t('Contact') }}: </b>
                    <span>{{ contact }}</span>
                </div>
                <div class="information-section mb-1" v-if="displayCommunication">
                    <b>{{ $t('Communication') }}: </b>
                    <span class="dot-separator">{{ person.properties.preferredLangKey }}</span>
                    <span class="dot-separator weight-6 text-danger" v-if="person.properties.doNotCommunicate">{{ $t('Do not communicate') }}</span>
                </div>
                <div class="information-section mb-0" v-if="hasTaxonomy">
                    <person-taxonomy-priority-meter-mini :value="person.properties.priority"></person-taxonomy-priority-meter-mini>
                    <person-taxonomy-label-tag v-for="label in person.properties.labels"
                                               :key="label"
                                               :value="{ name: label }"></person-taxonomy-label-tag>
                </div>
            </div>
        </div>
        <!-- ACTIONS (ABS POSITIONED DROPDOWN) -->
        <div>
            <b-dropdown variant="link" toggle-class="text-decoration-none" class="more-actions" no-caret>
                <template v-slot:button-content>
                    <span class="small">
                        <i class="fas fa-chevron-down text-muted"></i>
                    </span>
                </template>
                <b-dropdown-item :to="{ name: 'person', params: {id: person.id} }">{{ $t('common_action_display') }}</b-dropdown-item>
                <!-- @future IDEAS: preview and print - Add print to Person view too -->
                <!--
                <b-dropdown-item :to="{ name: 'person-edit', params: {id: person.id} }">{{ $t('common_action_preview') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'person-edit', params: {id: person.id} }">{{ $t('common_action_print') }}</b-dropdown-item>
                -->
                <b-dropdown-item :to="{ name: 'person-edit', params: {id: person.id} }">{{ $t('common_action_edit') }}</b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import SocialMediaUtils from '@/common/utils/social-media.utils';
import DomainTranslations from '@/modules/DomainTranslations';
import PersonTaxonomyPriorityMeterMini from '@/components/Person/PersonTaxonomyPriorityMeterMini';

function cleanStringOrNull (str) {
    if (typeof str !== 'string') return null;
    str = str.trim();
    if (str === '') return null;
    return str;
}

/**
 * Default Person listing item.
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 July 2020
 */
export default {
    name: 'PersonListingItemDefault',
    components: {
        PersonTaxonomyPriorityMeterMini
    },
    props: {
        person: {
            type: Object,
            required: true,
            default: null
        },
        defaultLanguageKey: {
            type: String,
            required: true,
            default: 'EN'
        }
    },
    data () {
        return {
            // Computed
            fullTitle: null,
            socialMediaList: [],
            displaySocialMediaList: false,
            displayFullTitleAndSocialMedia: false,
            demographics: null,
            contact: null,
            hasTaxonomy: false,
            displayCommunication: false
        };
    },
    beforeMount () {
        this.fullTitle = this.buildFullTitle();
        this.socialMediaList = this.buildSocialMediaList();
        this.displaySocialMediaList = this.buildDisplaySocialMediaList();
        this.displayFullTitleAndSocialMedia = this.buildDisplayFullTitleAndSocialMedia();
        this.demographics = this.buildDemographics();
        this.contact = this.buildContact();
        this.hasTaxonomy = this.buildHasTaxonomy();
        this.displayCommunication = this.buildDisplayCommunication();
    },
    methods: {
        buildFullTitle () {
            let list = [];
            list.push(cleanStringOrNull(this.person.properties.title));
            list.push(cleanStringOrNull(this.person.properties.department));
            list.push(cleanStringOrNull(this.person.properties.organization));
            list = list.filter(function (item) {
                return item !== null;
            });
            if (list.length === 0) return null;
            return list.join(', ');
        },
        buildSocialMediaList () {
            let list = [];
            list.push(SocialMediaUtils.detectSocialMediaAndBuildExternalLink('twitterUsername', this.person.properties.twitterUsername));
            list.push(SocialMediaUtils.detectSocialMediaAndBuildExternalLink('instagramUsername', this.person.properties.instagramUsername));
            list = list.filter(function (item) {
                return item !== null;
            });
            return list;
        },
        buildDisplaySocialMediaList () {
            return this.socialMediaList.length > 0;
        },
        buildDisplayFullTitleAndSocialMedia () {
            return this.fullTitle !== null || this.socialMediaList.length > 0;
        },
        buildDemographics () {
            let list = [];
            if (this.person.properties.age > 0) {
                list.push(this.person.properties.age + ' ' + this.$t('y.o.'));
            }
            if (this.person.properties.sex !== 'UNKNOWN') {
                list.push(DomainTranslations.personEnum(this.person.properties.sex, 'sex', this.person.properties.sex));
            }
            if (this.person.properties.maritalStatus !== 'UNKNOWN') {
                list.push(DomainTranslations.personEnum(this.person.properties.maritalStatus, 'maritalStatus', this.person.properties.maritalStatus));
            }
            list.push(cleanStringOrNull(this.person.properties.nationality));
            // @future I think Native Language is not necessary.
            list = list.filter(function (item) {
                return item !== null;
            });
            if (list.length === 0) return null;
            return list.join(', ');
        },
        buildContact () {
            let list = [];
            list.push(cleanStringOrNull(this.person.properties.email));
            list.push(cleanStringOrNull(this.person.properties.phoneNumber));
            list = list.filter(function (item) {
                return item !== null;
            });
            if (list.length === 0) return null;
            return list.join(', ');
        },
        buildHasTaxonomy () {
            return this.person.properties.labels.length > 0 || this.person.properties.priority > 0;
        },
        buildDisplayCommunication () {
            // If doNotCommunicate is true
            // OR if Person's preferred communication language key is different than tenant's.
            return this.person.properties.doNotCommunicate === true ||
                this.person.properties.preferredLangKey !== this.defaultLanguageKey;
        }
    }
};
</script>

<style lang="scss">
    .PersonListingItemDefault {
        // with title (full name) class '.size-6'
        font-size: 15px;

        // Styling
        &.card {
            border: none !important;
            box-shadow: 0 1px 3px 0 rgba(0,0,0,.1) !important;
        }

        .information-section:last-child {
            margin-bottom: 0 !important;
        }

        // Fixes
        .priority-meter {
            margin-right: .6em;
        }

        // Positioning
        .more-actions {
            position: absolute;
            top: 0;
            right: 0;

            i {
                color: #bfbfbf !important;
            }
        }

        .avatar-bottom-detail {
            bottom: -.625rem;
            left: 50%;
            position: absolute;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
        }

        // Utilities
        .dot-separator:not(:last-child):after {
            content: "•";
            color: #717171;
            padding: 0 .25rem;
        }
    }
</style>
