<template>
    <div class="BgcBuilder">
        <!-- HEADER -->
        <div class="BgcBuilder__Header d-flex align-items-center justify-content-start mb-2" v-if="displayHeader">
            <p class="mb-0 mr-1">{{ $t('Matches') }}</p>
            <select class="form-control form-control-sm g-width-auto mr-1"
                    v-model="bgc.operator">
                <option v-for="operatorItem in bgc.specific.operators"
                        :key="operatorItem.value"
                        :label="$t(operatorItem.labelAlt)"
                        :value="operatorItem.value">{{ $t(operatorItem.labelAlt) }}</option>
            </select>
            <!-- assuming that operator can be OR or AND fix sentence in Greek -->
            <p class="mb-0">{{ bgc.operator === 'AND' ? $t('of_the_following_and') : $t('of_the_following_or') }}:</p>
        </div>
        <!-- BODY -->
        <div class="BgcBuilder__Body">
            <gct-common-group v-for="(bgcChild, index) in bgc.children"
                              :key="bgcChild.specific.rId"
                              :bgc-initial="bgcChild"
                              :index="index"
                              v-on:change="onChildChange"
                              v-on:remove="onChildRemove"></gct-common-group>
        </div>
        <!-- FOOTER -->
        <div class="BgcBuilder__Footer">
            <bgc-select v-on:select="addGroup"></bgc-select>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import GcModel from './gc.model';
import GcConfig from './gc-config';
import GctCommonGroup from './gct-common-group';
import BgcSelect from './bgc-select';

/**
 * Generic Criteria Builder.
 *
 * Unfortunately it's tight-coupled to People logic and views.
 * In the future we should utilize this component for other views and domains too.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 */
export default {
    name: 'BgcBuilder',
    components: {
        GctCommonGroup,
        BgcSelect
    },
    props: {
        bgcInitial: {
            type: Object,
            required: false,
            default: null
        }
    },
    data () {
        return {
            bgc: null
        };
    },
    computed: {
        displayHeader () {
            return this.bgc.children.length > 1;
        }
    },
    beforeMount () {
        // @future PROPOSITION:
        // I think that if bgcInitial is null an error should be displayed.
        // It's right. Think about "separation of concerns". BUT it's not practical...
        if (this.bgcInitial === null) this.bgc = cloneDeep(GcModel.getBgcCommonGroup());
        else this.bgc = cloneDeep(this.bgcInitial);
        // @future We should validate the provided 'bgcInitial' obj and auto-correct if necessary.
        // On each change, send data to parent.
        this.$watch('bgc', {
            handler: debounce(function () {
                this.signalChange();
            }, GcConfig.commonDebounce),
            deep: true
        });
    },
    methods: {
        signalChange () {
            this.$emit('change', cloneDeep(this.bgc));
        },
        addGroup ($event) {
            if ($event === null) return;
            // @future We should validate the provided object.
            const newBgcCommonGroup = cloneDeep(GcModel.getBgcCommonGroup());
            newBgcCommonGroup.specific['rId'] = 'bgc-' + new Date().getTime(); // reactivity and rendering
            const newBgc = cloneDeep($event);
            newBgc.specific['rId'] = 'bgc-' + new Date().getTime(); // reactivity and rendering
            newBgcCommonGroup.children.push(newBgc);
            this.bgc.children.push(newBgcCommonGroup);
        },

        // Children
        // these methods trigger watcher which notifies parent components.
        onChildChange ({ bgc, index }) {
            if (typeof index !== 'number') return;
            // @future We should validate if the provided index is in range.
            // this.bgc.children[index] = bgc; // data from child are cloned.
            // @see https://vuejs.org/v2/guide/reactivity.html#For-Arrays
            this.$set(this.bgc.children, index, bgc);
        },
        onChildRemove ($event) {
            if (typeof $event !== 'number') return;
            // @future We should validate if the provided index is in range.
            this.bgc.children.splice($event, 1);
        }
    }
};
</script>

<style lang="scss">
    .BgcBuilder {
        font-size: 15px;
    }
</style>
