<template>
    <div class="PeopleWorkspaceView">
        <clv-head-meta :title="[
            initializing === true ? $t('common_state_loading') + '...' : null,
            workspaceBase.id === null ? $t('People Explorer') : workspaceBase.name
        ]"></clv-head-meta>

        <!-- VIEW LOADER -->
        <element-loading :active="initializing" :is-full-view="true" :lock-parent="true"></element-loading>

        <!--
        The v-if is necessary. When a Workspace changes, all children component must re-mount.
        -->
        <people-view v-if="!initializing"></people-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PeopleView from '@/modules/People/views/PeopleView';

/**
 * People Workspace View. Responsible for loading Workspace.
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 July 2020
 */
export default {
    name: 'PeopleWorkspaceView',
    components: {
        PeopleView
    },
    computed: {
        ...mapGetters('people', {
            initializing: 'initializing',
            workspaceBase: 'workspaceBase'
        })
    },
    watch: {
        '$route' () {
            this.explainAndRoute();
        }
    },
    beforeMount () {
        this.explainAndRoute();
    },
    methods: {
        explainAndRoute () {
            // Get the id from params.
            // IMPORTANT: the id can be null or undefined.
            let workspaceId = this.$route.params.id;
            if (!workspaceId) { // @see : https://stackoverflow.com/questions/5515310/is-there-a-standard-function-to-check-for-null-undefined-or-blank-variables-in
                // By doing this check, we ensure that user will always return to the last accessed workspace (for this application load).
                // If user has not accessed any workspace, the default workspace will be displayed.
                let workspaceIdToRedirect = 'default';
                if (this.workspaceBase.id) { // @see : https://stackoverflow.com/questions/5515310/is-there-a-standard-function-to-check-for-null-undefined-or-blank-variables-in
                    workspaceIdToRedirect = this.workspaceBase.id;
                }
                this.$router.replace({ name: 'people-workspace', params: { id: workspaceIdToRedirect } });
                return;
            }
            // Get ws base id. If base id exists (not NaN, or null, or empty or undefined, etc),
            // get id, else set 'default'. Setting 'default' is required for the comparison below.
            let wsBaseIdTemp = this.workspaceBase.id ? this.workspaceBase.id : 'default';
            // At this point, we assume that the id is valid (not necessary right).
            if (workspaceId === wsBaseIdTemp) {
                // Do nothing. The ws is already loaded to store.
            } else {
                // Perform a total store reset.
                // @future POTENTIAL BUG. This is async operation.
                this.$store.dispatch('people/resetModule').then(() => void 0).catch(() => void 0);

                // At this point ws does not exist in store.
                // - if id === 'default', get the default ws which exists in application (no need for remote call).
                // - Else, get workspace from back-end. If does not exist, set default ws.
                if (workspaceId === 'default') {
                    this.$store.dispatch('people/getWorkspaceDefault').then(() => void 0).catch(() => void 0);
                } else {
                    this.$store.dispatch('people/getWorkspace', workspaceId)
                        .then(() => void 0)
                        .catch((ignoredError) => {
                            // @future Display error/warning notification?
                            this.$router.replace({ name: 'people-workspace', params: { id: 'default' } });
                        });
                }
            }
        }
    }
};
</script>
