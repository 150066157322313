<template>
    <div class="GctCommonRangeFilter d-flex align-items-center justify-content-start">
        <!-- LABEL -->
        <div class="mr-2">
            <p class="mb-0 weight-6" :title="bgc.specific.label">{{ bgc.specific.label | ellipsis(24) }}</p>
        </div>
        <!-- OPERATOR -->
        <div class="mr-2" v-if="displayOperatorSelectControl">
            <select class="form-control form-control-sm"
                    v-model="bgc.operator"
                    :disabled="disableOperatorSelectControl">
                <option v-for="operatorItem in bgc.specific.operators"
                        :key="operatorItem.value"
                        :label="$t(operatorItem.value)"
                        :value="operatorItem.value">{{ $t(operatorItem.value) }}</option>
            </select>
        </div>
        <!-- INPUT / value -->
        <div class="mr-2">
            <!-- INPUT CONTROL / INTEGER / DECIMAL -->
            <div v-if="controlToBeDisplayed === 'defaultControlForIntegerOrDecimal'" key="defaultControlForIntegerOrDecimal_value">
                <el-input-number v-model="bgc.value"
                                 controls-position="right"
                                 :min="bgc.specific.minValue"
                                 :max="bgc.specific.maxValue"></el-input-number>
            </div>

            <!-- INPUT CONTROL / DATE & DATE TIME -->
            <div v-else-if="controlToBeDisplayed === 'defaultControlForDateOrDateTime'" key="defaultControlForDateOrDateTime_value">
                <el-date-picker v-model="bgc.value"
                                :type="datePickerType"></el-date-picker>
            </div>
        </div>
        <!-- INPUT / value2 -->
        <div class="mr-2">
            <!-- INPUT CONTROL / INTEGER / DECIMAL -->
            <div v-if="controlToBeDisplayed === 'defaultControlForIntegerOrDecimal'" key="defaultControlForIntegerOrDecimal_value2">
                <el-input-number v-model="bgc.value2"
                                 controls-position="right"
                                 :min="bgc.specific.minValue"
                                 :max="bgc.specific.maxValue"></el-input-number>
            </div>

            <!-- INPUT CONTROL / DATE & DATE TIME -->
            <div v-else-if="controlToBeDisplayed === 'defaultControlForDateOrDateTime'" key="defaultControlForDateOrDateTime_value2">
                <el-date-picker v-model="bgc.value2"
                                :type="datePickerType"></el-date-picker>
            </div>
        </div>
        <div class="ml-auto">
            <span class="cursor-pointer text-muted small" :title="$t('common_action_remove')" @click="signalRemove"><i class="fas fa-times"></i></span>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import GcConfig from './gc-config';
import BgcUtilities from './bgc-utilities';

/**
 * Responsible for GenericCriteriaType COMMON_RANGE_FILTER.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 */
export default {
    name: 'GctCommonRangeFilter',
    props: {
        bgcInitial: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            bgc: null,
            // UI States - computed on each change.
            displayOperatorSelectControl: false,
            disableOperatorSelectControl: false,
            controlToBeDisplayed: 'none',
            datePickerType: 'date'
        };
    },
    beforeMount () {
        this.bgc = cloneDeep(this.bgcInitial);
        this.datePickerType = this.getDatePickerType(); // Set once.
        this.syncUiStates();
        // On each change, send data to parent.
        this.$watch('bgc', {
            handler: debounce(function () {
                // If value is correct it will not change (and watcher will run once).
                // Otherwise value will changed and watcher will fire this function one more time.
                this.bgc.value = BgcUtilities.typingAutoCorrectBasedOnLang(this.bgc.value, this.bgc.lang);
                this.bgc.value2 = BgcUtilities.typingAutoCorrectBasedOnLang(this.bgc.value2, this.bgc.lang);
                this.syncUiStates();
                this.signalChange();
            }, GcConfig.commonDebounce),
            deep: true
        });
    },
    methods: {
        getControlToBeDisplayed () {
            const control = get(this.bgc, 'specific.control', null);
            const lang = get(this.bgc, 'lang', null);
            if (control === 'input' && (lang === 'INTEGER' || lang === 'DECIMAL')) return 'defaultControlForIntegerOrDecimal';
            else if (control === 'input' && lang === 'DATE') return 'defaultControlForDateOrDateTime';
            else if (control === 'input' && lang === 'DATETIME') return 'defaultControlForDateOrDateTime';
            else return 'none'; // Probably this is an error (mis-configured bgc)
        },
        getDatePickerType () {
            const lang = get(this.bgc, 'lang', null);
            if (lang === 'DATETIME') return 'datetime';
            else return 'date'; // The default. If it's invalid it will not be displayed.
        },
        syncUiStates () {
            this.controlToBeDisplayed = this.getControlToBeDisplayed();
            if (get(this.bgc, 'specific.operators', null) === null) {
                this.displayOperatorSelectControl = false;
                this.disableOperatorSelectControl = true;
            } else {
                this.displayOperatorSelectControl = get(this.bgc, 'specific.operators', []).length > 0;
                this.disableOperatorSelectControl = get(this.bgc, 'specific.operators', []).length === 0;
            }
        },
        // Event handling trigger by (form) controls
        signalChange () {
            this.$emit('change', {
                bgc: cloneDeep(this.bgc),
                index: this.index
            });
        },
        signalRemove () {
            this.$emit('remove', this.index);
        }
    }
};
</script>
