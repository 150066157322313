<template>
    <div class="PersonListingItemCompact d-flex justify-content-start align-items-center bg-white border-bottom">
        <div class="mr-2">
            <!--suppress JSUnresolvedVariable, HtmlUnknownTarget -->
            <avatar :username="person.properties.avatarLetters"
                    :size="36"
                    :src="person.properties.avatarUrl"
                    style="min-width: 36px;"
                    class="person-title-avatar"
                    backgroundColor="#dddddd"
                    color="#666666"></avatar>
        </div>
        <div>
            <router-link tag="p"
                         class="mb-0 mr-1 weight-7 cursor-pointer"
                         :to="{ name: 'person', params: {id: person.id} }">
                {{ person.properties.name }} <!--suppress JSUnresolvedVariable -->
                <span v-if="person.properties.deceased" :title="$t('Deceased')"> †</span>
            </router-link>
        </div>
    </div>
</template>

<script>
/**
 * Compact Person listing item [EXPERIMENTAL - WORK (AND CONCEPT) STILL IN PROGRESS].
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 July 2020
 */
export default {
    name: 'PersonListingItemCompact',
    props: {
        person: {
            type: Object,
            required: true,
            default: null
        }
    }
};
</script>

<style lang="scss">
    .PersonListingItemCompact {
        // margin: 1em;
        padding: 0.8em;
    }
</style>
