<template>
    <div class="PeopleView ViewWrapper LwHaB">
        <div class="d-md-flex flex-md-column LwHaB-Main Disable-md">
            <!-- HEADER -->
            <div class="LwHaB-Main__Header">
                <people-header-toolbar></people-header-toolbar>
                <people-header-settings></people-header-settings>
                <people-header-pageable></people-header-pageable>
            </div>
            <!-- BODY -->
            <div ref="mainContainer"
                 class="flex-grow-1 flex-shrink-1 LwHaB-Main__Body container"
                 :class="{ 'overflow-hidden': peopleFetching }">
                <element-loading :active="peopleFetching" :lock-parent="true" :background-color="'#f8f9fa'"></element-loading>
                <!-- EMPTY STATE -->
                <div class="mt-4" v-if="displayPeopleEmptyState">
                    <div v-if="displayPeopleFirstUseEmptyState" key="people-empty-state-first-use">
                        <p class="mt-4 mb-0">{{ $t('people_first_use_subtitle_1') }}</p>
                        <p class="small">{{ $t('people_first_use_prompt_mc_1') }}</p>
                        <router-link :to="{ query: { gcCreator: 'person' }}"
                                     tag="button"
                                     class="btn btn-accent1">{{ $t('people_first_use_btn_prompt_1') }}</router-link>
                    </div>
                    <div v-else key="people-empty-state">
                        <h4>{{ $t('common_search_no_results') }}</h4>
                        <p class="mt-4 mb-0">{{ $t('common_search_no_results_subtitle_1') }}</p>
                        <p class="small mb-0">{{ $t('common_search_no_results_tip_adv_1') }}</p>
                    </div>
                </div>
                <!-- ERROR STATE 2020-07-19 -->
                <simple-state-error :error="peopleFetchError" v-if="displayPeopleError"></simple-state-error>
                <people-body-listing v-if="displayPeopleListing"></people-body-listing>
                <people-body-listing-pagination v-if="displayPeopleListing"></people-body-listing-pagination>
            </div>
        </div>
        <people-filters-sidebar-right></people-filters-sidebar-right>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GSortersProvider from '@/modules/People/gc-builder/g-sorters-provider';
import PeopleHeaderToolbar from './PeopleHeaderToolbar';
import PeopleHeaderSettings from './PeopleHeaderSettings';
import PeopleHeaderPageable from './PeopleHeaderPageable';
import PeopleBodyListing from './PeopleBodyListing';
import PeopleFiltersSidebarRight from './PeopleFiltersSidebarRight';
import PeopleBodyListingPagination from './PeopleBodyListingPagination';

/**
 * People view. Nested view of 'PeopleWorkspaceView.vue'.
 * Responsible for basic layout and store initialization.
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 July 2020
 */
export default {
    name: 'PeopleView',
    components: {
        PeopleFiltersSidebarRight,
        PeopleHeaderToolbar,
        PeopleHeaderSettings,
        PeopleHeaderPageable,
        PeopleBodyListing,
        PeopleBodyListingPagination
    },
    computed: {
        ...mapGetters('people', {
            // 'people' sub-module
            peopleFetching: 'peopleFetching',
            peopleFetchError: 'peopleFetchError',
            displayPeopleListing: 'displayPeopleListing',
            displayPeopleEmptyState: 'displayPeopleEmptyState',
            displayPeopleFirstUseEmptyState: 'displayPeopleFirstUseEmptyState',
            displayPeopleError: 'displayPeopleError'
        })
    },
    watch: {
        // Every time this state indicator changes the main body scroll is locked
        // in order to display loading properly.
        'peopleFetching' () {
            this.resetScroll();
        }
    },
    beforeMount () {
        // Initializations that require Workspace instance.
        this.$store.dispatch('people/synchronizeBgcSubModule').then(() => void 0).catch(() => void 0);
        this.$store.dispatch('people/ensureSortingV0SubModule', GSortersProvider.getSortingOptionList())
            .then(() => void 0).catch(() => void 0);

        // Set (early) the people loader in order to provide a smooth user experience.
        this.$store.commit('people/setPeopleFetching', true);

        // Search for People.
        // Possible errors will be handle by components.
        this.$store.dispatch('people/searchPeople').then(() => void 0).catch(() => void 0);
    },
    methods: {
        resetScroll () {
            this.$refs.mainContainer.scrollTop = 0;
        }
    }
};
</script>

<style lang="scss">
    .PeopleView {

        .ElementLoading-Overlay {
            z-index: 1000 !important; // Necessary when off canvas sidebar is open.
        }
    }

    // Usage: btn btn--people
    // EXPERIMENTAL @future make this generic and move to styles.
    .btn--people {
        user-select: none;
        background-color: #fff;
        border-radius: .1333rem;
        font-size: .6875rem;
        font-weight: 600;
        line-height: 1.5;
        padding: .375rem .625rem;
        border: 1px solid #dbdbdb;
        box-shadow: none;
        color: #6a6a6a;
        white-space: nowrap;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .btn--people--borderless {
        border: 1px solid transparent;
    }
    .btn--people--borderless:hover {
        border: 1px solid #dbdbdb;
    }
</style>
