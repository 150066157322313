<template>
    <div class="PeopleHeaderToolbar bg-white border-bottom">
        <div class="container">
            <div class="d-flex align-items-center justify-content-between py-3">
                <div class="d-flex align-items-center justify-content-start">
                    <button class="btn btn--people btn--people--borderless mr-1"
                            :title="$tc('entity Workspace', 2)"
                            @click="goToWorkspaces">
                        <i class="fas fa-bars"></i>
                    </button>
                    <p class="mb-0 weight-9 size-6 line-height-2" v-if="workspaceWorkingIsDefault" key="workspace-default-name">{{ $t('People') }}</p>
                    <p class="mb-0 weight-9 size-6 line-height-2" v-else key="workspace-name" :title="workspaceWorkingName">{{ workspaceWorkingName | ellipsis(40) }}</p>
                </div>
                <div class="C-D-None-md-Down">
                    <!--
                    @future .text-primary when it's active.
                    But what does this mean?
                    If has multiple filters but all are inactive or even invalid?
                    -->
                    <button class="btn btn--people ml-1 mr-2"
                            :title="$t('Filters')"
                            @click="toggleFilters">
                        <i class="fas fa-filter"></i>
                    </button>
                    <!--suppress HtmlUnknownAttribute -->
                    <div class="d-inline" v-tooltip="applicationQuotasUiEntityQluVTooltipObject('workspace')">
                        <button class="btn btn--people ml-1"
                                @click="createWorkspace"
                                :disabled="applicationQuotasUiEntityQluDisabled('workspace')"
                                v-if="workspaceWorkingIsDefault">
                            {{ $t('common_action_save_as') }}
                        </button>
                    </div>
                    <button class="btn btn--people ml-1"
                            @click="saveWorkspace"
                            v-if="!workspaceWorkingIsDefault">
                        {{ $t('common_action_save') }}
                    </button>
                    <!--
                    There are many things to consider.
                    - UI/UX: With this implementation, reloading is required. But that's not... "smooth".
                    - Notification
                    - Enable only when WS is changed
                    - etc.
                    @future Think about it (after ver.alpha)
                    <button class="btn btn--people ml-1"
                            :title="$t('common_action_discard')"
                            @click="discardWorkspaceChanges"
                            :disabled="!workspaceChanged"
                            v-if="!workspaceWorkingIsDefault">
                        <i class="fas fa-undo"></i>
                    </button>
                    -->
                    <!--
                    @future Settings will be included in a later release.
                    <button class="btn btn--people ml-1"
                            :title="$t('Settings')"
                            :class="{ 'text-primary': uiSettingsOpened }"
                            @click="toggleSettings">
                        <i class="fas fa-cog"></i>
                    </button>
                    -->
                    <button class="btn btn--people ml-1 text-danger"
                            :title="$t('common_action_delete')"
                            @click="deleteWorkspace"
                            v-if="!workspaceWorkingIsDefault">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { WorkspaceService } from '@/common/services/api.service';

/**
 * People toolbar.
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 July 2020
 */
export default {
    name: 'PeopleHeaderToolbar',
    computed: {
        ...mapGetters('application', {
            applicationQuotasUiEntityQluDisabled: 'quotasUiEntityQluDisabled',
            applicationQuotasUiEntityQluVTooltipObject: 'quotasUiEntityQluVTooltipObject'
        }),
        ...mapGetters('people', {
            // 'workspace' sub-module
            workspaceWorkingIsDefault: 'workspaceWorkingIsDefault',
            workspaceWorkingName: 'workspaceWorkingName',
            // 'ui' sub-module
            uiSettingsOpened: 'uiSettingsOpened'
        })
    },
    methods: {
        goToWorkspaces () {
            this.$router.push({ name: 'workspaces' });
        },
        toggleFilters () {
            this.$store.commit('people/uiSidebarRightOpenedToggle');
        },
        // Stateless update of working Workspace.
        saveWorkspace () {
            const workspace = cloneDeep(this.$store.getters['people/workspaceWorking']);
            // Do the necessary resets and then save.
            workspace.searchDetails.page = 0;
            WorkspaceService.updateWorkspace(workspace)
                .then(() => {
                    this.toastDefault('Workspace updated');
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },
        createWorkspace () {
            // Get the name from user and check it.
            let name = prompt(this.$t('Please enter Workspace name'));
            if (typeof name !== 'string') return;
            if (name.trim() === '') {
                this.toastError(this.$t('Workspace must have a name'));
                return;
            }
            if (name.length > 100) {
                this.toastError(this.$t('Workspace name is too long'));
                return;
            }

            // Commit name and then create.
            // No need perform any further actions.
            // The route will change and the module will initialized again.
            this.$store.commit('people/setWorkspaceWorkingName', name);
            return this.$store.dispatch('people/createWorkspace')
                .then((data) => {
                    this.toastDefault('Workspace created');
                    this.updateWorkspaceEntityQlu();
                    this.$router.push({ name: 'people-workspace', params: { id: data.id } });
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },
        discardWorkspaceChanges () {
            // Not yet implemented. CHECK NOTES IN BUTTON COMMENTS.
        },
        deleteWorkspace () {
            let resp = confirm(this.$t('Delete Workspace?'));
            if (!resp) return;

            return this.$store.dispatch('people/deleteWorkspace')
                .then(() => {
                    this.toastDefault('Workspace deleted');
                    this.updateWorkspaceEntityQlu();
                    this.$router.push({ name: 'workspaces' });
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },
        toggleSettings () {
            this.$store.commit('people/uiSettingsOpenedToggle');
        },

        // Utils
        updateWorkspaceEntityQlu () {
            // 2020-03-13-@future-reduce-scattered-calls.
            this.$store.dispatch('application/quotasFindOneEntityQlu', 'workspace')
                .then(() => void 0)
                .catch(() => void 0);
        }
    }
};
</script>
