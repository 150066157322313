<template>
    <div class="BgcSelect">
        <el-cascader v-if="displayCascader"
                     ref="cascaderControl"
                     :placeholder="$t('Add criteria')"
                     v-model="selectedValue"
                     :options="cascaderOptions"
                     :show-all-levels="false"
                     filterable
                     @change="handleCascaderChange"
                     size="mini">
            <template slot-scope="{ node, data }">
                <span>{{ data.label }} <small v-if="data.value.type === 'COMMON_RANGE_FILTER'">{{ $t('Range') }}</small></span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
            </template>
        </el-cascader>
    </div>
</template>

<script>
/**
 * Wraps Element (library) cascader.
 * Cascader options are also filterable.
 *
 * @future i18n Implementation: provide functions to cascader and do not translate labels in store.
 * By doing this we minimize the sources that have to process information (in order to ensure consistency).
 * Check el-cascader 'beforeFilter' prop
 * Check el-cascader 'filterMethod' prop
 *
 * It's necessary for this component to be mounted only after People module is initialized.
 * Otherwise, cascaderOptions won't set.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 */
export default {
    name: 'BgcSelect',
    data () {
        return {
            selectedValue: null,
            displayCascader: false,
            cascaderOptions: []
        };
    },
    beforeMount () {
        // I know, that's make this component tight-coupled to store. But what can I do...
        // @future Find solution.
        // PROPOSITION 1:
        // Pass option 'getFromStore' and provide path 'storeGetterPath'.
        // That way we can reuse bgc-builder.
        // Caveat: I have to modify components in order to accept theses props
        // (bgc-builder looks like tree and props should be passed to all nodes down-way).
        // PROPOSITION 2:
        // Pass function that provides cascader options. It this okay?
        // Extra load? Bind this? Etc...
        this.cascaderOptions = this.$store.getters['people/bgcForCascader'];
    },
    mounted () {
        this.displayCascader = true;
    },
    methods: {
        handleCascaderChange (value) {
            if (value !== null) {
                if (value.length > 0) {
                    let valueToEmit = value[value.length - 1];
                    // @future : Validate valueToEmit ?
                    this.$emit('select', valueToEmit);
                }
            }
            this.displayCascader = false; // Hide cascader.
            this.selectedValue = null;
            setTimeout(function () {
                // @see : Temporary solution.
                this.displayCascader = true; // Show cascader.
            }.bind(this), 150);
        }
    }
};
</script>
