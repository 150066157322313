<template>
    <div class="GctCommonCommunicationSubscriptionFilter d-flex align-items-center justify-content-start">
        <!-- LABEL -->
        <div class="mr-2">
            <p class="mb-0 weight-6" :title="bgc.specific.label">{{ bgc.specific.label | ellipsis(24) }}</p>
        </div>
        <!-- COMMUNICATION SUBSCRIPTION SELECT -->
        <div class="mr-2">
            <select class="form-control form-control-sm"
                    v-model="bgc.value">
                <option v-for="comSubType in communicationSubscriptionTypes"
                        :key="comSubType"
                        :label="$t(comSubType)"
                        :value="comSubType">{{ $t(comSubType) }}</option>
            </select>
        </div>
        <!-- INPUT / value -->
        <div class="mr-2">
            <b-form-checkbox v-model="bgc.value2"
                             :value="true"
                             :unchecked-value="false"
                             size="lg"></b-form-checkbox>
        </div>
        <div class="ml-auto">
            <span class="cursor-pointer text-muted small" :title="$t('common_action_remove')" @click="signalRemove"><i class="fas fa-times"></i></span>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import GcConfig from './gc-config';

/**
 * Responsible for GenericCriteriaType COMMUNICATION_SUBSCRIPTION_FILTER.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 */
export default {
    name: 'GctCommonCommunicationSubscriptionFilter',
    props: {
        bgcInitial: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            communicationSubscriptionTypes: [
                'MARKETING_EMAIL',
                'ONE_TO_ONE_EMAIL',
                'MARKETING_SMS',
                'ONE_TO_ONE_SMS',
                'MARKETING_CALL',
                'ONE_TO_ONE_CALL'
            ],
            bgc: null
        };
    },
    beforeMount () {
        this.bgc = cloneDeep(this.bgcInitial);
        // On each change, send data to parent.
        this.$watch('bgc', {
            handler: debounce(function () {
                this.signalChange();
            }, GcConfig.commonDebounce),
            deep: true
        });
    },
    methods: {
        // Event handling trigger by (form) controls
        signalChange () {
            this.$emit('change', {
                bgc: cloneDeep(this.bgc),
                index: this.index
            });
        },
        signalRemove () {
            this.$emit('remove', this.index);
        }
    }
};
</script>
