const BgcUtilities = {};

/**
 * Corrects user input.
 * Auto corrector helper during typing.
 *
 * If value and lang cannot be handled, value will be returned as it is.
 *
 * ONLY 'INTEGER' AND 'DECIMAL' ARE SUPPORTED CURRENTLY!
 *
 * @param value the value
 * @param lang the GenericCriteriaLang
 * @param correctNull if true and value is null
 *        it will return the default-safe value for the provided lang.
 * @param correctWrongType if true and value type is not right
 *        it will return the default-safe value for the provided lang.
 */
BgcUtilities.typingAutoCorrectBasedOnLang = function (value, lang, correctNull = false, correctWrongType = true) {
    if (typeof lang !== 'string') return value; // Return as it is.
    // @future Validate lang? Enum values.

    // Correct null.
    if (value === null) {
        if (correctNull === true) {
            switch (lang) {
            case 'INTEGER':
            case 'DECIMAL':
                return 0;
            default:
                return null;
            }
        } else {
            return null;
        }
    }

    // At this point, value cannot be null.
    switch (lang) {
    case 'INTEGER':
    case 'DECIMAL':
        if (typeof value !== 'number') return correctWrongType === true ? 0 : value;
        if (isNaN(value)) return correctWrongType === true ? 0 : value;
        return value;
    default:
        return value; // return as it is.
    }
};

export default BgcUtilities;
