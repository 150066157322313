<template>
    <div class="PeopleBodyListing">
        <div v-if="workspaceWorkingView === 'COMPACT'" key="people-listing-compact">
            <person-listing-item-compact v-for="person in people"
                                         :key="person.id"
                                         :person="person"></person-listing-item-compact>
        </div>
        <div v-else key="people-listing-default">
            <person-listing-item-default v-for="person in people"
                                         :key="person.id"
                                         :person="person"
                                         :default-language-key="defaultLanguageKey"></person-listing-item-default>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonListingItemCompact from '@/modules/People/components/PersonListingItemCompact';
import PersonListingItemDefault from '@/modules/People/components/PersonListingItemDefault';

/**
 * Responsible for rendering People listing items based on active view.
 * Listing items can have multiple forms. It's up to the user.
 * By default it will be rendered PersonListingItemDefault.vue for each Person.
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 July 2020
 */
export default {
    name: 'PeopleBodyListing',
    components: {
        PersonListingItemDefault,
        PersonListingItemCompact
    },
    computed: {
        ...mapGetters('people', {
            // 'people' sub-module.
            people: 'people',
            // 'workspace' sub-module.
            workspaceWorkingView: 'workspaceWorkingView'
        })
    },
    data () {
        return {
            defaultLanguageKey: window.cs3w.get('defaultLanguageKey', 'EN')
        };
    }
};
</script>
