<template>
    <b-sidebar id="PeopleView-OffCanvasSidebar-Right"
               right
               :bg-variant="'white'"
               :width="width"
               :no-close-on-route-change="true"
               v-model="visible">
        <p class="mb-0 size-5" slot="title">{{ $t('Filters') }}</p>
        <div class="p-4 text-center" v-if="bgcSynchronizing" key="bgc-builder-loading">
            <p class="mb-0">{{ $t('common_state_loading') }}</p>
        </div>
        <div class="p-4" v-else key="bgc-builder-loaded">
            <bgc-builder :bgc-initial="bgc"
                         v-on:change="onBgcChange"></bgc-builder>
        </div>
        <template v-slot:footer="{ hide }">
            <div class="d-flex align-items-center px-3 py-3 border-top">
                <button class="btn btn-primary mr-1" @click="applyGcAndSearch" :disabled="disableSubmit">{{ $t('common_action_filter') }}</button>
                <button class="btn btn-secondary" @click="hide">{{ $t('common_action_close') }}</button>
            </div>
        </template>
    </b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import BgcBuilder from '@/modules/People/gc-builder/bgc-builder';

/**
 * Off canvas sidebar implemented by bootstrap-vue.
 * Responsible for rendering of the right filtering components based on search details
 * and for triggering store actions based on changes made on that components.
 *
 * @future IDEA: Before user applies filters, display the number of people in query (on header? or somewhere else?).
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 July 2020
 */
export default {
    name: 'PeopleFiltersSidebarRight',
    components: {
        BgcBuilder
    },
    computed: {
        ...mapGetters('people', {
            // 'bgc' sub-module
            bgc: 'bgc',
            bgcSynchronizing: 'bgcSynchronizing',
            // 'ui' sub-module
            uiSidebarRightOpened: 'uiSidebarRightOpened'
        }),
        visible: {
            get () {
                return this.uiSidebarRightOpened;
            },
            set (value) {
                this.$store.commit('people/uiSidebarRightOpenedSet', value);
            }
        },
        width () {
            return '50%';
        }
    },
    data () {
        return {
            disableSubmit: true
        };
    },
    methods: {
        // on change, update working gc (do not search).
        onBgcChange ($event) {
            this.disableSubmit = true; // Disable button (it will take some ms to convert bgc to gc).
            this.$store.commit('people/setBgcAndGc', cloneDeep($event));
            // Search on change (remove if you enable button).
            // this.$store.dispatch('people/searchPeople').then(() => void 0).catch(() => void 0);
            this.disableSubmit = false; // GC changed, enable button.
        },
        applyGcAndSearch () {
            this.disableSubmit = true; // Disable until next change.
            this.$store.dispatch('people/searchPeople').then(() => void 0).catch(() => void 0);
            // this.$store.commit('people/uiSidebarRightOpenedSet', false); // Close sidebar.
        }
    }
};
</script>

<style lang="scss">
    #PeopleView-OffCanvasSidebar-Right {
        top: 60px;
        height: calc(100vh - 60px) !important;
        background: #fff;
        border-left: 1px solid gainsboro;
        z-index: 2000;

        .b-sidebar-header {
            border-bottom: 1px solid gainsboro;
            // min/max-height, height should be equal to People inner bar.
            min-height: 63px;
            max-height: 63px;
            height: 63px;

            .close {
                margin-right: .4em;
            }
        }
    }
</style>
