<template>
    <div class="GctCommonArrayFilter d-flex align-items-center justify-content-start">
        <!-- LABEL -->
        <div class="mr-2">
            <p class="mb-0 weight-6" :title="bgc.specific.label">{{ bgc.specific.label | ellipsis(24) }}</p>
        </div>
        <!-- OPERATOR -->
        <div class="mr-2" v-if="displayOperatorSelectControl">
            <select class="form-control form-control-sm"
                    v-model="bgc.operator"
                    :disabled="disableOperatorSelectControl">
                <option v-for="operatorItem in bgc.specific.operators"
                        :key="operatorItem.value"
                        :label="$t(operatorItem.value)"
                        :value="operatorItem.value">{{ $t(operatorItem.value) }}</option>
            </select>
        </div>
        <!-- INPUT -->
        <div class="flex-grow-1 mr-2" v-if="controlToBeDisplayed === 'defaultControl'">
            <el-select class="g-width-100"
                       v-model="bgc.value"
                       multiple
                       filterable
                       allow-create
                       default-first-option
                       :multiple-limit="20">
                <!-- a basic limit that ensures service proper use : 20 options -->
                <el-option v-for="item in selectControlOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">{{ item.label }}</el-option>
            </el-select>
        </div>
        <div class="ml-auto">
            <span class="cursor-pointer text-muted small" :title="$t('common_action_remove')" @click="signalRemove"><i class="fas fa-times"></i></span>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import GcConfig from './gc-config';

/**
 * Responsible for GenericCriteriaType COMMON_ARRAY_FILTER.
 *
 * Search operation supports any type of arrays but property domain does not.
 * That's why currently this component supports only arrays of strings.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 */
export default {
    name: 'GctCommonArrayFilter',
    props: {
        bgcInitial: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            selectControlOptions: [], // @future Here will be stored the results from remote search (autocomplete).
            bgc: null,
            // UI States - computed on each change.
            displayOperatorSelectControl: false,
            disableOperatorSelectControl: false,
            controlToBeDisplayed: 'none'
        };
    },
    beforeMount () {
        this.bgc = cloneDeep(this.bgcInitial);
        this.syncUiStates();
        // On each change, send data to parent.
        this.$watch('bgc', {
            handler: debounce(function () {
                this.syncUiStates();
                this.signalChange();
            }, GcConfig.commonDebounce),
            deep: true
        });
    },
    methods: {
        getControlToBeDisplayed () {
            // const control = get(this.bgc, 'specific.control', null);
            // const lang = get(this.bgc, 'specific.lang', null);
            const operator = get(this.bgc, 'operator', null);
            if (operator === 'EMPTY' || operator === 'NOT_EMPTY' || operator === 'NULL' || operator === 'NOT_NULL') return 'none';
            return 'defaultControl';
        },
        syncUiStates () {
            this.controlToBeDisplayed = this.getControlToBeDisplayed();
            if (get(this.bgc, 'specific.operators', null) === null) {
                this.displayOperatorSelectControl = false;
                this.disableOperatorSelectControl = true;
            } else {
                this.displayOperatorSelectControl = get(this.bgc, 'specific.operators', []).length > 0;
                this.disableOperatorSelectControl = get(this.bgc, 'specific.operators', []).length === 0;
            }
        },
        // Event handling trigger by (form) controls
        signalChange () {
            this.$emit('change', {
                bgc: cloneDeep(this.bgc),
                index: this.index
            });
        },
        signalRemove () {
            this.$emit('remove', this.index);
        }
    }
};
</script>
